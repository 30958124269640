export const onBoardNames = {
  OFFER: {
    CHOOSE_TYPE: 'chooseType',
    NAVIGATE: 'offerNavigate',
    STEPS: {
      MEMBERS_DATA: 'membersData',
      POSITION: 'position',
      PAYMENT: 'payment',
      POLiCY: 'policy',
      REVIEW: 'review',
    },
  },
  LEGAL_ENTITIES: 'legalEntities',
  PROJECTS: 'projects',
  CALENDAR: 'calendars',
  POLICY: 'policies',
};

export const urlWithTours = ['team/add/offer', 'team/add', 'member-hr/home'];

import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { IconButtonWithContainer } from 'src/components_payroll/Buttons';
import { IconCancel, IconQuestion } from 'src/components_payroll/Icons';
import DropDownMenu from 'src/components_payroll/DropDownMenu';

import * as authSelectors from 'src/store/auth/selectors';

import { onBoardNames } from '../constants';
import { OnBoardContext } from '../context';

export function TourControlButton({ run, setRun, setIsAutoRun, setStepIndex, isHunterHomePage }) {
  const { setTour } = useContext(OnBoardContext);
  const user = useSelector(authSelectors.getUser);

  const menuItems = useMemo(() => {
    const tours = [{ title: 'Create new Offer', onClick: () => setTour(onBoardNames.OFFER.NAVIGATE, true) }];

    if (user?.role === 'hunter-admin') {
      tours.push(
        { title: 'Create Legal entity', onClick: () => setTour(onBoardNames.LEGAL_ENTITIES, true) },
        { title: 'Create Project', onClick: () => setTour(onBoardNames.PROJECTS, true) },
        { title: 'Create Calendar', onClick: () => setTour(onBoardNames.CALENDAR, true) },
        { title: 'Create Policy', onClick: () => setTour(onBoardNames.POLICY, true) },
      );
    }

    return tours;
  }, [user?.role, setTour]);

  const runTour = () => {
    setStepIndex(0);
    setRun(true);
    setIsAutoRun(true);
  };

  const stopTour = () => {
    setStepIndex(0);
    setRun(false);
    setIsAutoRun(false);
  };

  return (
    <Box
      position="absolute"
      top="50px"
      right="50px"
      width={isHunterHomePage && !run ? '11rem' : '4rem'}
      height={isHunterHomePage && !run ? '3rem' : '4rem'}
    >
      {run && (
        <IconButtonWithContainer
          Icon={<IconCancel color="light.primary.shades.60p" height="1.5rem" width="1.5rem" />}
          onClick={stopTour}
        />
      )}
      {!run && isHunterHomePage && (
        <DropDownMenu buttonTitle="Get started with Juggl" Icon={IconQuestion} buttonVariant="light" menuItems={menuItems} />
      )}
      {!run && !isHunterHomePage && (
        <IconButtonWithContainer
          Icon={<IconQuestion color="light.primary.shades.60p" height="1.5rem" width="1.5rem" />}
          onClick={runTour}
        />
      )}
    </Box>
  );
}

export default TourControlButton;

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { LINEAR_SIZES } from 'src/constants/chat';

export const Container = styled(Box)`
  width: 375px;
  max-width: 375px;
  height: ${({ minimizeChat }) => (minimizeChat ? `${LINEAR_SIZES.header}px` : `${LINEAR_SIZES.fullWidth}px`)};
  z-index: 10000;
  overflow: hidden;
  position: fixed;
  transition: top 0.3s;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: ${({ minimizeChat }) => (minimizeChat ? '15px 15px 0px 0px' : '15px 15px 15px 15px')};
`;

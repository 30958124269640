/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@mui/material';

import * as chatSelectors from 'src/store/chat/selectors';

import Message from '../Message/Message';

import { Container } from './MessageList.style';

const PAGE_SIZE = 15;

const MessageList = ({ chat, user }) => {
  const socket = useSelector(chatSelectors.getSocket);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [users, setUsers] = useState({});
  const aditionalScrollOptions = useRef({});
  const container = useRef(null);

  const getDateString = useCallback(date => {
    const now = moment();
    const timestamp = moment(date || now);
    const isToday = timestamp.isSame(now, 'day');
    const isYesterday = timestamp.isSame(now.subtract(1, 'days'), 'day');

    if (isToday) {
      return timestamp.format('HH:mm');
    }

    if (isYesterday) {
      return `yesterday at ${timestamp.format('HH:mm')}`;
    }

    return timestamp.format('DD:MM:YYYY HH:mm');
  }, []);

  const scrollHandler = useCallback(
    e => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const currentScrollPosition = Math.abs(scrollTop) + clientHeight;

      if (scrollHeight - currentScrollPosition < 100 && page < maxPage) {
        setPage(page + 1);
      }
    },
    [maxPage, page],
  );

  useEffect(() => {
    if (chat?.messages?.length) setMaxPage(Math.ceil(chat.messages.length / PAGE_SIZE));
  }, [chat?.messages]);

  useEffect(() => {
    if (container.current) {
      container.current.scrollTo({
        top: container.current.scrollHeight,
        ...aditionalScrollOptions.current,
      });
      aditionalScrollOptions.current = { behavior: 'smooth' };
    }
  }, [chat?.messages?.length, container, aditionalScrollOptions]);

  useEffect(() => {
    const newUsers = chat.members.reduce((acc, member) => ({ ...acc, [member.userId]: member.user }), {});

    setUsers(newUsers);
  }, [chat.members]);

  useEffect(() => {
    if (user && chat.messages?.length) {
      const { userId, status } = chat.messages[0];

      if (userId !== user.id && socket.connected && status === 'sent') {
        socket.emit('message:read', {
          chat: chat.id,
          userIdToMark: userId,
        });
      }
    }
  }, [chat, user, socket]);

  return (
    <Container direction="column" spacing={1} componnt="Paper" ref={container} onScroll={scrollHandler}>
      {chat.messages?.length ? (
        chat.messages.slice(0, page * PAGE_SIZE).map((message, i) => {
          const { userId, type, text, timestamp, status, value } = message;
          const now = Date.now();

          if (!users[userId]) {
            users[userId] = {
              firstName: 'Unknown',
              lastName: '',
            };
          }

          return (
            <Message
              key={i}
              position={user.id === userId ? 'right' : 'left'}
              type={type || 'text'}
              title={user.id === userId ? undefined : `${users[userId].firstName} ${users[userId].lastName}`}
              text={text}
              date={timestamp || now}
              dateString={getDateString(timestamp)}
              status={status}
              data={type === 'photo' ? { uri: `${process.env.REACT_APP_API_URL}/v1/uploads/file?filename=${value}` } : undefined}
            />
          );
        })
      ) : (
        <Typography sx={{ textAlign: 'center' }}>No messages yet</Typography>
      )}
    </Container>
  );
};

export default MessageList;

import { Stack, Typography, styled } from '@mui/material';

import { IconSmileMeh } from 'src/components_payroll/Icons';

const Container = styled(Stack)(({ theme, isClickable }) => {
  const clickableStyles = isClickable
    ? {
        cursor: 'pointer',
        ':hover': {
          borderColor: theme.palette.light.primary.shades['20p'],
          backgroundColor: theme.palette.light.primary.shades['10p'],
        },
      }
    : {};

  return {
    gap: '0.5rem',
    padding: '1rem',
    borderRadius: '0.5rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.light.neutral_grey['5p'],
    backgroundColor: theme.palette.light.neutral_grey['2p'],
    ...clickableStyles,
  };
});

export const SummaryNonContent = ({ type }) => (
  <Container>
    <Stack direction="row" gap="0.5rem" justifyContent="center">
      <IconSmileMeh color="light.primary.shades.60p" />
      <Typography variant="BODY1" color="light.neutral_grey.70p">
        No {type} yet
      </Typography>
    </Stack>
  </Container>
);

export const SummaryCard = ({ leftTop, leftBottom, rightTop, rightBottom, onClick }) => (
  <Container onClick={onClick} isClickable={Boolean(onClick)}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="SUBTITLE1" color="light.neutral_grey.90p">
        {leftTop}
      </Typography>
      <Typography variant="SUBTITLE1" color="light.neutral_grey.90p">
        {leftBottom}
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="CAPTION" color="light.neutral_grey.50p">
        {rightTop}
      </Typography>
      <Typography variant="CAPTION" color="light.neutral_grey.50p">
        {rightBottom}
      </Typography>
    </Stack>
  </Container>
);

import { Stack } from '@mui/material';

import { useSelector } from 'react-redux';

import { useMemo } from 'react';

import HelpBlock from 'src/onboard/components/HelpBlock';
import { TextLink } from 'src/components_payroll/Texts';

import * as authSelectors from 'src/store/auth/selectors';

export const PolicyTooltip = () => {
  const user = useSelector(authSelectors.getUser);

  const isUserAdmin = useMemo(() => user?.role === 'hunter-admin', [user]);

  return (
    <Stack gap="1rem">
      <HelpBlock title="Create Policy">
        {isUserAdmin
          ? `Create a new policy ${(<TextLink title="here" navigateTo="/member-admin/settings/policies" />)}.`
          : "If you didn't find a necessary policy, please contact admin."}
      </HelpBlock>
    </Stack>
  );
};

import { Stack, styled, Typography } from '@mui/material';

import CardContainer from './Container';

export const ContentContainerGrey = styled(Stack)(({ theme, onClick, gap }) => {
  const clickableStyles = onClick
    ? {
        cursor: 'pointer',
        ':hover': {
          borderColor: theme.palette.light.primary.shades['20p'],
          backgroundColor: theme.palette.light.primary.shades['10p'],
        },
      }
    : {};

  return {
    gap: gap || '0.5rem',
    padding: '1rem',
    borderRadius: '0.5rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.light.neutral_grey['5p'],
    backgroundColor: theme.palette.light.neutral_grey['2p'],
    ...clickableStyles,
  };
});

const CardContainerTitle = ({ Icon, title, action }) => (
  <Stack direction="row" justifyContent="space-between">
    <Stack direction="row" gap="0.38rem" alignItems="center">
      {Icon && <Icon color="light.primary.shades.60p" />}
      <Typography variant="H4" color="light.neutral_grey.90p">
        {title}
      </Typography>
    </Stack>
    <Stack>{action}</Stack>
  </Stack>
);

export const CardContainerWithTitle = ({ Icon, title, children, gap = '1rem', action }) => (
  <CardContainer>
    <Stack gap={gap}>
      <CardContainerTitle Icon={Icon} title={title} action={action} />

      {children}
    </Stack>
  </CardContainer>
);

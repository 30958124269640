import HelpBlock from '../components/HelpBlock';

export default [
  {
    target: '[tour="hunter-settings-btn"]',
    content: <HelpBlock title="Enter Company Details">Start here</HelpBlock>,
    navigateTo: '/company/settings/general',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    target: '[tour="policies-settings"]',
    content: <HelpBlock title="Select Policies" />,
    navigateTo: '/member-admin/settings/policies',
    hideFooter: true,
  },
  {
    target: '[tour="policy-project-select"]',
    content: <HelpBlock title="Select Project">Please select a project for which the policy will be created. </HelpBlock>,
    placement: 'top',
    hideFooter: true,
  },
  {
    target: '[tour="policy-create-modal"]',
    content: (
      <HelpBlock title="Enter Policy Name">Please enter a name for the policy. You can change it later if you need to.</HelpBlock>
    ),
    placement: 'left',
    hideBackButton: true,
  },
];

import HelpBlock from '../components/HelpBlock';

export default [
  {
    target: '[tour="hunter-settings-btn"]',
    content: <HelpBlock title="Enter Company Details">Start here</HelpBlock>,
    navigateTo: '/company/settings/general',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    target: '[tour="calendars-settings"]',
    content: <HelpBlock title="Select Calendars" />,
    navigateTo: '/member-admin/settings/calendars',
    hideFooter: true,
  },
  {
    target: '[tour="add-new-calendar-btn"]',
    content: <HelpBlock title="Click here" />,
    hideFooter: true,
  },
  {
    target: '[tour="calendar-create-modal"]',
    content: (
      <HelpBlock title="Enter Calendar Name">Please enter a name for the calendar. You can change it later if you need to.</HelpBlock>
    ),
    placement: 'left',
    hideBackButton: true,
  },
];

import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, AvatarGroup, Box, Grid, IconButton, Stack } from '@mui/material';
import { MdClose, MdOutlineMoreVert, MdOutlineWest, MdRemove } from 'react-icons/md';

import * as chatActions from 'src/store/chat/actions';
import * as chatSelectors from 'src/store/chat/selectors';
import useAction from 'src/utils/useAction';

import { Container, StyledBox } from './Header.style';

const Header = ({ chat, user, back }) => {
  const setShow = useAction(chatActions.setShow);
  const setMinimize = useAction(chatActions.setMinimize);
  const minimizeChat = useSelector(chatSelectors.getMinimizeChat);

  const minimizeHandler = () => setMinimize(!minimizeChat);
  const closeHandler = () => setShow(false);

  if (chat) {
    const users = chat.members.reduce((acc, member) => ({ ...acc, [member.userId]: member.user }), {});

    const avatarBox =
      Object.keys(users).length > 1 ? (
        <AvatarGroup max={2} sx={{ float: 'left' }}>
          {Object.keys(users).map((e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Avatar key={i} />
          ))}
        </AvatarGroup>
      ) : (
        <Avatar />
      );

    return (
      <Container container direction="row">
        <Grid
          item
          xs={2}
          sx={{
            p: '5px 0 0 5px',
            cursor: 'pointer',
          }}
        >
          <IconButton color="icWhite" onClick={back} item xs={2}>
            <MdOutlineWest color="rgba(255, 255, 255, 0.56)" size={24} />
          </IconButton>
        </Grid>
        {chat.members.length > 2 ? (
          <Grid item xs={8}>
            <Stack direction="row" sx={{ wordBreak: 'break-word', overflow: 'hidden' }}>
              {avatarBox}
              <Box sx={{ ml: 1 }}>
                <StyledBox>{chat?.vacancy?.role?.skill}</StyledBox>
                <StyledBox font="14px" color="rgba(255, 255, 255, 0.7)">
                  Subheader
                </StyledBox>
              </Box>
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={8}>
            <Stack direction="row" display="flex" alignItems="center" sx={{ wordBreak: 'break-word', overflow: 'hidden' }}>
              {avatarBox}
              <Box sx={{ ml: 1 }}>
                <StyledBox>{`${user.firstName} ${user.lastName}`}</StyledBox>
              </Box>
            </Stack>
          </Grid>
        )}
        <Grid
          item
          xs={2}
          sx={{
            p: '5px 0 0 10px',
          }}
        >
          <IconButton color="icWhite" item xs={2}>
            <MdOutlineMoreVert color="rgba(255, 255, 255, 0.56)" size={24} />
          </IconButton>
        </Grid>
      </Container>
    );
  }

  return (
    <Container container direction="row" sx={{ justifyContent: 'flex-end' }}>
      <IconButton color="icWhite" item xs={2} onClick={minimizeHandler}>
        {minimizeChat ? (
          <MdRemove color="rgba(255, 255, 255, 0.56)" size={24} />
        ) : (
          <MdRemove color="rgba(255, 255, 255, 0.56)" size={24} />
        )}
      </IconButton>
      <IconButton color="icWhite" item xs={2} onClick={closeHandler}>
        <MdClose color="rgba(255, 255, 255, 0.56)" size={24} />
      </IconButton>
    </Container>
  );
};

export default Header;
